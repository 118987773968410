import * as React from 'react';

import { getFromLocalStorage, updateLocalStorage } from 'components/notification/libs/localStorageService';
import { getNotificationCache, updateNotificationCache } from 'components/notification/libs/notificationCacheService';
import { fetchNotifications } from 'components/notification/libs/fetchNotificationsService';
import HeaderNotificationManager from './HeaderNotificationManager';
import { Notification } from 'components/notification/type';

/*
  HeaderNotificationにまつわるContainer Component
  APIによるお知らせの取得とlocal storageの読み取りと更新を行う
  ストーリーは書かない
*/

const HeaderNotificationContainer: React.FC = () => {
  // 一度に取得するお知らせの件数
  const limit = 100;

  const [lastReadTime, setLastReadTime] = React.useState<Date | null>(getFromLocalStorage);
  const [notifications, setNotifications] = React.useState<Notification[]>();

  // お知らせのキャッシュ
  // お知らせや未読管理の変更に合わせて更新されれば良いのでstateにはしない
  const notificationCache = getNotificationCache();

  // キャッシュの更新コールバック関数
  const updateCache = React.useCallback((unreadCount: number, shouldUpdateLastFetchedAt = false) => {
    updateNotificationCache(unreadCount, shouldUpdateLastFetchedAt);
  }, []);

  // lastReadTimeとlocal storageを更新する
  // lastReadTimeとtime stampの値をsyncさせる
  const updateLastReadTime = React.useCallback(
    (date: Date) => {
      updateLocalStorage(date);
      setLastReadTime(date);

      // お知らせが閲覧された状態なのでキャッシュの未読件数を0にする
      if (notificationCache && notificationCache.unreadCount > 0) {
        // 未読件数を0にする(最終取得時間の更新は行わない)
        updateNotificationCache(0);
      }
    },
    [notificationCache]
  );

  // お知らせを取得するコールバック関数
  const fetch = React.useCallback(() => {
    fetchNotifications(limit).then((res: Notification[]) => {
      setNotifications(res);
    });
  }, []);

  return <HeaderNotificationManager notifications={notifications} lastReadTime={lastReadTime} updateLastReadTime={updateLastReadTime} cache={notificationCache} updateCache={updateCache} fetchNotifications={fetch} />;
};

export default HeaderNotificationContainer;
