import { getFromLocalStorage } from 'components/notification/libs/localStorageService';

/*
 お知らせ通知キャッシュ
 - 未読件数
 - 配信対象
 - 最後にお知らせを取得した時間
 */
export type NotificationCache = {
  unreadCount: number;
  target: string;
  lastFetchedAt: number | null;
};

/* お知らせ通知キャッシュのキー */
export const notificationCacheKey = 'notification_cache';

/* お知らせ通知キャッシュの有効期限 */
const notificationCacheTtl = 1000 * 60 * 60;

/* お知らせ通知キャッシュの配信対象 - 会員 */
export const notificationCacheTargetValueMember = 'member';
/* お知らせ通知キャッシュの配信対象 - ユーザー(非会員) */
export const notificationCacheTargetValueUser = 'user';

/*
  お知らせ通知キャッシュの取得
 */
export const getNotificationCache = (): NotificationCache | null => {
  const cache = localStorage.getItem(notificationCacheKey);
  if (!cache) return null;
  try {
    return JSON.parse(cache) as NotificationCache;
  } catch (e) {
    localStorage.removeItem(notificationCacheKey);
  }

  return null;
};

/*
  お知らせ通知キャッシュの更新
 */
export const updateNotificationCache = (unreadCount: number, shouldUpdateLastFetchedAt = false): void => {
  const cache = getNotificationCache() || {
    unreadCount: 0,
    target: notificationCacheTargetValueUser,
    lastFetchedAt: null,
  };

  cache.unreadCount = unreadCount;
  cache.target = isLogin() ? notificationCacheTargetValueMember : notificationCacheTargetValueUser;
  if (shouldUpdateLastFetchedAt) {
    cache.lastFetchedAt = Date.now();
  }

  localStorage.setItem(notificationCacheKey, JSON.stringify(cache));
};

/*
  お知らせ通知キャッシュの有効性チェック
  - 以下の条件を全て満たす場合に有効と判定する
    - キャッシュが存在する場合
    - キャッシュが期限切れでない場合
    - ログイン状態が変わっていない場合
 */
export const isValid = (cache: NotificationCache | null): boolean => cache !== null && !isExpiredCache(cache.lastFetchedAt) && !isChangedLoginStatus(cache.target) && isValidUnreadCount(cache.unreadCount);

/*
  お知らせ通知キャッシュの有効期限切れチェック(期限切れの場合はtrueを返す)
 */
const isExpiredCache = (lastFetchedAt: number | null): boolean => {
  // 初めてのアクセス時などではtrueを返す
  if (lastFetchedAt === null) return true;

  // 期限切れかどうかを判定する
  if (Date.now() - lastFetchedAt > notificationCacheTtl) return true;

  // 最後にお知らせを読んだ時間がキャッシュ時間より新しい場合は期限切れとする
  const lastReadAt = getFromLocalStorage();
  if (lastReadAt === null) return false;
  return lastReadAt.getTime() > lastFetchedAt;
};

/*
  ログイン状態を判定する
  クッキーにmember_uidがあるかで簡易的にログイン判定を行う
 */
const isLogin = (): boolean => document.cookie.includes('member_uid');

/*
  ログイン状態の変化チェック(変化がある場合はtrueを返す)
 */
const isChangedLoginStatus = (target: string): boolean => {
  const newTarget = isLogin() ? notificationCacheTargetValueMember : notificationCacheTargetValueUser;
  return target !== newTarget;
};

/*
  未読件数の有効性チェック(有効な場合はtrueを返す)
 */
const isValidUnreadCount = (unreadCount: number): boolean => unreadCount >= 0;
